import { Swiper, Navigation, Pagination } from "swiper/dist/js/swiper.esm.js";

Swiper.use([Navigation, Pagination]);

export default () => {
    var swiper = new Swiper('.gallery-modal-slider', {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
    });

    var slideHome = new Swiper('.solutions-slide', {
      slidesPerView: 2.7,
      spaceBetween: 10,
      watchOverflow:true,
      navigation: {
        nextEl: '.next',
        prevEl: '.prev',
      },
      breakpoints: {
        600: {
          slidesPerView: 1
        },
        960: {
          slidesPerView: 2
        },
      }
    });

    var brandSlide = new Swiper('.brand-container', {
      watchOverflow:true,
      navigation: {
        nextEl: '.button-next',
        prevEl: '.button-prev',
      },
    });
};
