export default function menuControl(){
    let leftMenuCall = document.querySelector('.desktop-side-menu-call');

    if(leftMenuCall){
        leftMenuCall.addEventListener('click', function(){
            document.querySelector('.side-desktop-menu').classList.toggle('is-active');
        })
    }

    let fullMenuCall = document.querySelector('.desktop-full-menu-call');
    if(fullMenuCall){
        fullMenuCall.addEventListener('click', function(){
            document.querySelector('.full-desktop-menu').classList.toggle('is-active');
        })
    }

    let changeBurger = document.querySelector('.burger-icon-container');
    let menuItem = document.querySelectorAll('.menu-item');
    if (menuItem){
        menuItem.forEach(element => {
            element.addEventListener('click', function(){
                document.body.classList.remove('mobile-menu-is-open');
                document.querySelector('.main-menu-mobile').classList.remove('is-active');
                changeBurger.classList.remove('is-active');
            });
        });
    }
}