export default function formControl(){
    
    if (formOpen){
        let formOpen = document.querySelectorAll('.open-form');
        formOpen.forEach(element => {
            element.addEventListener('click', function(){
                event.preventDefault();
                element.parentNode.classList.add('is-active');
                element.classList.add('is-active');
            });
        });
    }
}